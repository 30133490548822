import React from 'react'

import { AccountLayout } from '../../components'

const AccountWishListTemplate = ({ pageContext }) => {
  const { lang, page } = pageContext

  return (
    <AccountLayout lang={lang} switcher={page}>
      <p>AccountWishListsTemplate</p>
    </AccountLayout>
  )
}

export default AccountWishListTemplate
